<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
import { doc, getDoc } from "firebase/firestore";
import { db } from "@/service/firebase";
import etapaCompra from "../../constants/etapaCompra";

export default {
  data() {
    return {
      token: "",
      modal: false,
      loading: true,
    };
  },
  beforeMount() {
    this.validaLocalStorage();
  },
  methods: {
    async verificaExistenciaTipo(compra_id) {
      let docRef = doc(db, "compras", `${compra_id}`);
      let docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        if (docSnap.data().etapa !== etapaCompra.UPLOAD) {
          localStorage.removeItem("compra_id");
          console.log("compra não está em UPLOAD");
          this.$router.push("/404");
        } else {
          this.$store.commit("compra/setCompra", docSnap.data());
        }
      } else {
        console.log("compra não existe (verificaExistenciaTipo else)");
        this.$router.push("/404");
      }
    },
    validaLocalStorage() {
      let compraID = localStorage.getItem("compra_id");
      if (compraID && compraID.length > 3) {
        this.verificaExistenciaTipo(compraID);
      } else {
        console.log("compra_id não existe (validaLocalStorage)");
        this.$router.push("/404");
      }
    },
  },
};
</script>
